import React from "react"

import SEO from "../components/seo"
import { useProjectImages } from "../hooks/useProjectImages.component"
import { PROJECTS_DATA } from "../projects/PROJECTS_DATA"
import Breadcrumbs from "./Breadcrumbs.component"
import Intro from "./Intro.component"
import DynamicGatsbyImage from "../components/gatsbyImage/DynamicGatsbyImage.component"
import SectionHeader from "../components/sectionHeader/SectionHeader.component"
import ProjectsThumbnail from "../components/projectsPage/ProjectsThumbnail.component"
import Panels from "./Panels.component"
import ImageCopy from "./ImageCopy.component"
import Video from "../components/video/Video.component"

const ProjectPage = ({ pageContext }) => {
  const project = pageContext

  const {
    heroImage,
    fullWidthImage1,
    leftSideImage,
    fullWidthImage2,
    panelLeft,
    panelRight,
    bottomHero,
    bottomHero2,
    bottomHero3,
  } = useProjectImages()

  const prevIndex = project.id === 0 ? PROJECTS_DATA.length - 1 : project.id - 1
  const nextIndex = (project.id + 1) % PROJECTS_DATA.length
  const prevProject = PROJECTS_DATA[prevIndex]
  const nextProject = PROJECTS_DATA[nextIndex]

  const displayCopy1 =
    project.copy1.length > 0 && project.information1.length > 0
  const displayInformation2 = project.information2.length > 0
  const displayFullWidthImage1 = fullWidthImage1.edges.find(
    ({ node }) =>
      node.childImageSharp.fluid.originalName === `${project.query}.jpg`
  )
  const displayFullWidthImage2 = fullWidthImage2.edges.find(
    ({ node }) =>
      node.childImageSharp.fluid.originalName === `${project.query}.jpg`
  )
  const displayBottomHero = bottomHero.edges.find(
    ({ node }) =>
      node.childImageSharp.fluid.originalName === `${project.query}.jpg`
  )
  const displayBottomHero2 = bottomHero2.edges.find(
    ({ node }) =>
      node.childImageSharp.fluid.originalName === `${project.query}.jpg`
  )
  const displayBottomHero3 = bottomHero3.edges.find(
    ({ node }) =>
      node.childImageSharp.fluid.originalName === `${project.query}.jpg`
  )

  return (
    <>
      <SEO title={project.name} />
      <article className="project-page">
        <Breadcrumbs name={project.name} />
        <div className="hero-container">
          <DynamicGatsbyImage data={heroImage} query={project.query} />
        </div>
        <Intro
          name={project.name}
          address={project.address}
          location={project.location}
        />
        {displayCopy1 && (
          <div className="copy copy1">
            <div>
              {project.copy1.map((text, index) => (
                <p key={index}>{text.paragraph}</p>
              ))}
            </div>
            {project.information1.length > 0 && (
              <div className="information information1">
                {project.information1.map((info, index) => (
                  <div key={index}>
                    <h1 className="heading">{info.heading}</h1>
                    <h3 className="subheading">{info.subheading}</h3>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {displayFullWidthImage1 && project.query !== 'mapleton' && (
          <div className="hero-container">
            <DynamicGatsbyImage data={fullWidthImage1} query={project.query} />
          </div>
        )}
        {displayFullWidthImage1 && project.query === 'mapleton' && (
          <div className="hero-container">
          <Video url="https://player.vimeo.com/progressive_redirect/playback/647608330/rendition/1080p?loc=external&signature=9b6b5921a998b7b41b47c45f6cd747a182834ed9f9fdb53bb636ee7610306915" />
          </div>
        )}
        <ImageCopy
          leftSideImage={leftSideImage}
          query={project.query}
          copy={project.copy2}
        />
        {displayInformation2 && (
          <div className="information information2">
            {project.information2.map((info, index) => (
              <div key={index}>
                <h1 className="heading">{info.heading}</h1>
                <h3 className="subheading">{info.subheading}</h3>
              </div>
            ))}
          </div>
        )}
        {project.panels && (
          <Panels
            panelLeft={panelLeft}
            panelRight={panelRight}
            query={project.query}
          />
        )}
        {displayFullWidthImage2 && (
          <div className="hero-container">
            <DynamicGatsbyImage data={fullWidthImage2} query={project.query} />
          </div>
        )}
        {displayBottomHero && (
          <div className="hero-container">
            <DynamicGatsbyImage data={bottomHero} query={project.query} />
          </div>
        )}
        {displayBottomHero2 && (
          <div className="hero-container">
            <DynamicGatsbyImage data={bottomHero2} query={project.query} />
          </div>
        )}
        {displayBottomHero3 && (
          <div className="hero-container">
            <DynamicGatsbyImage data={bottomHero3} query={project.query} />
          </div>
        )}
        <SectionHeader headline="More projects" />
        <section className="more-projects">
          <div className="thumbnail">
            <ProjectsThumbnail
              data={heroImage}
              query={prevProject.query}
              url={prevProject.url}
              name={prevProject.name}
              developer={prevProject.developer}
              address={prevProject.address}
              location={prevProject.location}
            />
          </div>
          <div className="thumbnail">
            <ProjectsThumbnail
              data={heroImage}
              query={nextProject.query}
              url={nextProject.url}
              name={nextProject.name}
              developer={nextProject.developer}
              address={nextProject.address}
              location={nextProject.location}
            />
          </div>
        </section>
      </article>
    </>
  )
}

export default ProjectPage
