import React from "react"
import { Link } from "gatsby"

import Arrow from "../images/arrow.svg"

const Breadcrumbs = ({ name }) => {
  return (
    <div className="breadcrumbs">
      <span>
        <Link to="/projects">Projects</Link>
      </span>
      <img src={Arrow} alt="arrow" />
      <span>{name}</span>
    </div>
  )
}

export default Breadcrumbs
