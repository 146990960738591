import React from "react"

import GatsbyImage from "./GatsbyImage.component"

const DynamicGatsbyImage = ({ data, query }) => {
  const image = data.edges.find(
    ({ node }) => node.childImageSharp.fluid.originalName === `${query}.jpg`
  )

  return image ? <GatsbyImage image={image.node.childImageSharp.fluid} /> : null
}

export default DynamicGatsbyImage
