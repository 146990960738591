import React from "react"

const Intro = ({ name, address, location }) => {
  return (
    <header className="intro">
      <h1>{name}</h1>
      <h3>{address}</h3>
      <h3>{location}</h3>
    </header>
  )
}

export default Intro
