import React from "react"

import DynamicGatsbyImage from "../components/gatsbyImage/DynamicGatsbyImage.component"

const ImageCopy = ({ leftSideImage, query, copy }) => {
  return (
    <div className="image-copy">
      <div className="left-side-image">
        <DynamicGatsbyImage data={leftSideImage} query={query} />
      </div>
      <div className="copy">
        {copy.map((text, index) => (
          <p key={index}>{text.paragraph}</p>
        ))}
      </div>
    </div>
  )
}

export default ImageCopy
