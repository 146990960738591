import React from "react"

import DynamicGatsbyImage from "../components/gatsbyImage/DynamicGatsbyImage.component"

const Panels = ({ panelLeft, panelRight, query }) => {
  return (
    <div className="panels">
      <div className="panel-left">
        <DynamicGatsbyImage data={panelLeft} query={query} />
      </div>
      <div className="panel-right">
        <DynamicGatsbyImage data={panelRight} query={query} />
      </div>
    </div>
  )
}

export default Panels
