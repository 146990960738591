const PROJECTS_DATA = [
  {
    id: 0,
    query: "mapleton",
    url: "/projects/the-mapleton-estate",
    name: "The Mapleton Estate",
    developer: "Landry Architects, Argent Design",
    address: "355 South Mapleton Drive",
    location: "Los Angeles",
    copy1: [
      {
        paragraph:
          "The Mapleton Estate (the “Estate”), is unique in all aspects, situated on an expansive 3 acres of flat land comprised of two combined lots and is located on one of the most-coveted streets in the Holmby Hills area of Los Angeles.",
      },
      {
        paragraph:
          "The Estate is designed by award winning architect Richard Landry and London based Interior Designers Argent Design, with a 41,700 square foot main residence adjoining a great lawn exceeding 11,000 square feet and bordered on two sides by pavilions.",
      },
    ],
    information1: [
      {
        heading: "Design & Permits",
        subheading: "Status",
      },
      {
        heading: "Dobacor",
        subheading: "Developer",
      },
      {
        heading: "Landry Architects",
        subheading: "Architect",
      },
      {
        heading: "41,700",
        subheading: "Square Feet",
      },
      {
        heading: "129,137",
        subheading: "Square Feet (Lot Size)",
      },
    ],
    copy2: [
      {
        paragraph:
          "Planned amenities include the finest set of comforts to be expected of an ultra-luxury estate, including a private movie theater amongst the largest in the world at 1,610 square feet.",
      },
      {
        paragraph:
          "The Estate is enriched with history and glory. 355 South Mapleton is the filming location of the film Mommie Dearest starring Academy Award winning Hollywood actress Faye Dunaway, and the former home of the early motion picture pioneer Allan Dwan. 333 South Mapleton is famously known as the “Playboy Bunny Hutch”, where Hugh Hefner’s Playboy Bunnies often resided.",
      },
    ],
    information2: [
      { heading: 12, subheading: "Bedrooms" },
      { heading: 30, subheading: "Bathrooms" },
      { heading: "1,610", subheading: "Square Feet Movie Theater" },
      {
        heading: "A piece of LA history",
        subheading: "Former “Playboy Bunny Hutch”",
      },
    ],
    panels: false,
  },
  {
    id: 1,
    query: "belair",
    url: "/projects/bel-air-road",
    name: "The Bel Air Estate",
    developer: "Foster + Partners",
    address: "332 Bel Air Road",
    location: "Los Angeles",
    copy1: [
      {
        paragraph:
          "The Bel Air Estate (the “Estate”) is a combined 2.3-acre lot in prime Bel Air, Los Angeles. The architect of the Estate is Foster + Partners, the practice led by the world-renowned architect Lord Norman Foster, multiple winner of the Stirling and Pritzker Architecture prizes.",
      },
      {
        paragraph:
          "Foster + Partners have provided an unrivaled design of a planned 35,000-foot residence, conveying an unmatched level of sophistication in a true modern residential masterpiece.",
      },
      {
        paragraph:
          "When completed, the Bel Air Estate will be the rarest of properties – the site of the only single-family house designed by Foster + Partners available for sale in North America. Designs have been provided for an architecturally significant 300-foot driveway and arrival sequence leading to a vast scale entry experience – a true statement of arrival curated by Foster + Partners.",
      },
    ],
    information1: [
      {
        heading: "Design & Permits",
        subheading: "Status",
      },
      {
        heading: "Dobacor",
        subheading: "Developer",
      },
      {
        heading: "Foster + Partners",
        subheading: "Architect",
      },
      {
        heading: "27,500",
        subheading: "Square Feet",
      },
      {
        heading: "2.3",
        subheading: "Acres (Lot Size)",
      },
    ],
    copy2: [
      {
        paragraph:
          "Environmental sustainability is a core focus for the design team – the main residence exhibits numerous environmentally friendly features while the emphasis placed on the connection with nature is reflected in the landscape architecture, exterior hardscape areas and roof garden on the Estate. The Estate features state of the art security systems designed by Gavin DeBecker and Associates, and complete privacy provided with mature landscaping.",
      },
    ],
    information2: [
      { heading: 6, subheading: "Bedrooms" },
      { heading: 10, subheading: "Bathrooms" },
      { heading: 300, subheading: "Foot Driveway" },
      {
        heading: "A piece of LA history",
        subheading:
          "The site of the only single-family house designed by Foster + Partners available for sale in North America",
      },
    ],
    panels: false,
  },
  {
    id: 2,
    query: "robertson-tower",
    url: "/projects/robertson-tower",
    name: "Robertson Tower",
    developer: "Design & Permits",
    address: "Robertson Boulevard",
    location: "Los Angeles",
    copy1: [
      {
        paragraph:
          "A mixed-use tower with a luxury boutique hotel and condos will provide an influx of tourists and residents with a high level of disposable income into the area.",
      },
      {
        paragraph:
          "Luxury brands targeting this particular demographic will be drawn to open stores in the adjoining retail space, revitalizing Robertson’s image as a shopping destination.",
      },
      {
        paragraph:
          "The plan is to construct a high quality, high density, mixed use tower with a mall and restaurants, commercial office space and a boutique hotel and condos. Our proposal is to have seven residential floors and six hotel floors and the lower floors to be used as commercial space and a mall.",
      },
    ],
    information1: [
      { heading: "Design & Permits", subheading: "Status" },
      { heading: "Dobacor", subheading: "Developer" },
      { heading: "Hotel, Retail, Office", subheading: "Type" },
      { heading: "12-16 TBD", subheading: "Floors" },
      { heading: "75,000", subheading: "Area Square Feet" },
    ],
    copy2: [],
    information2: [],
    panels: true,
  },
  {
    id: 3,
    query: "central-park-west",
    url: "/projects/central-park-west",
    name: "Central Park West",
    developer: "Foster + Partners",
    address: "Central Park West",
    location: "New York",
    copy1: [
      {
        paragraph:
          "Central Park West is a 5,400 Square Feet two story duplex in one of the most iconic residential buildings in the world by Robert A.M. Stern. It is situated in New York’s most exclusive neighborhood, with spectacular views of Central Park and the Manhattan skyline.",
      },
      {
        paragraph:
          "The Central Park West Project is designed by Foster + Partners, the practice led by the world-renowned architect Lord Norman Foster, multiple winner of the Stirling and Pritzker Architecture prizes.",
      },
    ],
    information1: [
      { heading: "In Construction", subheading: "Status" },
      { heading: "Dobacor", subheading: "Developer" },
      { heading: "5", subheading: "Bed Rooms" },
      { heading: "6.5", subheading: "Bathrooms" },
      { heading: "5,400", subheading: "Sqft" },
    ],
    copy2: [],
    information2: [],
    panels: false,
  },
  {
    id: 4,
    query: "esports",
    url: "/projects/esports-bar",
    name: "Esports Bar",
    developer: "Coming Soon",
    address: "Robertson Boulevard",
    location: "Los Angeles",
    copy1: [
      {
        paragraph:
          "eSports is quickly becoming a spectator sport, with millions of fans all over the world attending live events or tuning in on TV. The tremendous growth in popularity of esports has contributed to the demand for social settings for esports fans.",
      },
      {
        paragraph:
          "Our plan is to create a gaming-themed venue, where fans are able to enjoy food and drinks while watching their favorite teams and players compete on TV. In the back, there will be a mini arena equipped with high-end gaming consoles and computers for solo gameplay or casual and competitive tournaments.",
      },
    ],
    information1: [
      { heading: "Design & Permits", subheading: "Status" },
      { heading: "Dobacor", subheading: "Developer" },
      { heading: "Altair Studio", subheading: "Partners" },
      { heading: "Esports & Gaming", subheading: "Type" },
      { heading: "8,000", subheading: "Sqft" },
    ],
    copy2: [],
    information2: [],
    panels: false,
  },
]

module.exports = { PROJECTS_DATA }
