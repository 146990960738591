import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import DynamicGatsbyImage from "../gatsbyImage/DynamicGatsbyImage.component"

const ProjectsThumbnail = ({
  childVariant,
  data,
  query,
  url,
  name,
  developer,
  address,
  location,
}) => {
  return (
    <motion.div variants={childVariant} className="projects-thumbnail">
      <Link to={url}>
        <div className="thumbnail-container">
          <DynamicGatsbyImage data={data} query={query} />
        </div>
      </Link>
      <div className="text">
        <h1>{name}</h1>
        <h3>{developer}</h3>
        <p>{address}</p>
        <p>{location}</p>
      </div>
    </motion.div>
  )
}

export default ProjectsThumbnail
