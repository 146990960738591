import { useStaticQuery, graphql } from "gatsby"

export const useProjectImages = () => {
  const data = useStaticQuery(graphql`
    {
      heroImage: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "hero-image" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      fullWidthImage1: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "full-width-image-1" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      leftSideImage: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "left-side-image" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 743) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      fullWidthImage2: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "full-width-image-2" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      panelLeft: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "panel-left" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 808) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      panelRight: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "panel-right" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 808) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      bottomHero: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "bottom-hero" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      bottomHero2: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "bottom-hero-2" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      bottomHero3: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "bottom-hero-3" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const heroImage = data.heroImage
  const fullWidthImage1 = data.fullWidthImage1
  const leftSideImage = data.leftSideImage
  const fullWidthImage2 = data.fullWidthImage2
  const panelLeft = data.panelLeft
  const panelRight = data.panelRight
  const bottomHero = data.bottomHero
  const bottomHero2 = data.bottomHero2
  const bottomHero3 = data.bottomHero3

  return {
    heroImage,
    fullWidthImage1,
    leftSideImage,
    fullWidthImage2,
    panelLeft,
    panelRight,
    bottomHero,
    bottomHero2,
    bottomHero3,
  }
}
