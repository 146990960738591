import React from "react"

const SectionHeader = ({ headline }) => {
  return (
    <div className="section-header">
      <h1>{headline}</h1>
    </div>
  )
}

export default SectionHeader
